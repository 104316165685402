import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      {/* <span>&copy; 2023. Market AI. Todos los derechos reservados.</span>
      <span>Desarrollado por: <Link to="https://grupodeproinf.com/es/inicio/" target="_blank">Grupo Deproinf</Link></span> */}
    </div>
  )
}